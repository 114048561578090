
.phone{
    z-index: 1;
    position: absolute;
}

@media only screen and (max-width: 767px) {
    .phone{
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .phone{
        width: 780px;
        height: 950px;
        margin-left: 10px;
        margin-top: -80px;
    }
}

@media only screen and (min-width: 834px) {
    .phone{
        width: 780px;
        height: 1000px;
        margin-left: 50px;
        margin-top: -80px;
    }
}

@media only screen and (min-width: 1024px) {
    .phone{
        width: 780px;
        height: 1400px;
        margin-left: 120px;
        margin-top: -80px;
    }
}

@media only screen and (min-width: 1280px) {
    .phone{
        width: 760px;
        height: 900px;
        margin-left: 280px;
        margin-top: -100px;
    }
}

@media only screen and (min-width: 1440px) {
    .phone{
        width: 760px;
        height: 1000px;
        margin-left: 330px;
        margin-top: -100px;
    }
}

@media only screen and (min-width: 1900px) {
    .phone{
        display: block;
        width: 760px;
        height: 1050px;
        margin-left: 580px;
        margin-top: -100px;
    }
}