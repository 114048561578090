
.container_submenu{
    width: 200px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
}
.container_submenu::-webkit-scrollbar{
    background: #fafafa;
    opacity: 0;
    display: none;
}
.container_submenu::-webkit-scrollbar-thumb{
    background: #fafafa;
    opacity: 0;
}

.container_variants{
    height: 110px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.button_default{
    min-width: 150px;
    height: 47px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    color: #c9c6b7;
    background-color: #fff;
    /* border-color: #c9c6b7; */
    border: 2px solid #c9c6b7;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
}

.button_select{
    min-width: 150px;
    height: 47px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    color: #fff;
    background-color: #c9c6b7;
    border: 2px solid #c9c6b7;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
}

.scroll_options{
    width: 270px;
    overflow-x: auto;
    scrollbar-width: none;
}

.scroll_options::-webkit-scrollbar{
    background: #fff;
    opacity: 0;
}
.scroll_options::-webkit-scrollbar-thumb{
    background: #fff;
    opacity: 0;
}

.description{
    overflow-y: auto;
    scrollbar-width: none;
}

.image_row1{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    object-fit: scale-down;
    position: absolute;
    left: 0%;
    z-index: 1;
    animation: animation_image 15s infinite both ease-in-out;
}

.image_modal_row1{
    width: 100%;
    margin-right: 10px;
    object-fit: scale-down;
    position: absolute;
    left: 0%;
    z-index: 1;
    animation: animation_image 15s infinite both ease-in-out;
}

@keyframes animation_image{
    0%,50%,100%{
        opacity: 1;
    }
    25%,75%{
        opacity: 0;
    }
}

.image_row2{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    object-fit: scale-down;
}

.image_modal_row2{
    width: 100%;
    margin-right: 10px;
    object-fit: scale-down;
}

.container_close{
    position: absolute;
    right: 0px;
    top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}


/* iphone 5/SE */
@media only screen and (min-width: 320px) {
    .container_submenu{
        width: 270px;
        /* width: 25%; */
    }
    .scroll_options{
        width: 250px;
    }
}

/* mi cel */
@media only screen and (min-width: 340px) {
    .container_submenu{
        width: 320px;
    }
    .scroll_options{
        width: 290px;
    }
}

/* iphone 6/7/8 y mi cel */
@media only screen and (min-width: 375px) {
    .container_submenu{
        width: 330px;
    }
    .scroll_options{
        width: 310px;
    }
}

/* iphone 6/7/8 plus */
@media only screen and (min-width: 380px) {
    .container_submenu{
        width: 340px;
    }
    .scroll_options{
        width: 330px;
    }
}

/* iphone 6/7/8 plus */
@media only screen and (min-width: 414px) {
    .container_submenu{
        width: 370px;
    }
}

@media only screen and (min-width: 768px) {
    .container_submenu{
        width: 400px;
    }
    .scroll_options{
        width: 385px;
    }
}

@media only screen and (min-width: 834px) {
    .container_submenu{
        width: 400px;
    }
}

@media only screen and (min-width: 1024px) {
    .container_submenu{
        width: 455px;
    }
}

@media only screen and (min-width: 1280px) {
    .container_submenu{
        width: 450px;
        scrollbar-width: thin;
    }
}

@media only screen and (min-width: 1900px) {
    .container_submenu{
        width: 458px;
        scrollbar-width: thin;
    }
}